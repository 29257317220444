import React, { useEffect, useState } from 'react';
import Gender from '../../models/gender';
import InstallationModel from '../../models/installationModel';
import InstallationsService from '../../services/installationsService';
import UsersService, { UserPreCheckType } from '../../services/usersService';
import Popup from '../popup';
import TermsPanel from '../termsPanel';

import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import DataProtectionPanel from '../dataProtectionPanel';
// import { analytics } from '../database';
// import { logEvent } from 'firebase/analytics';


export default function HomePage() {

    let { modus } = useParams();

    const minPasswordLength = 8;
    const maxPages = 6;

    const [errorMessage, setErrorMessage] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [userVerified, setUserVerified] = useState(false);

    const [termsPopupVisible, setTermsPopupVisible] = useState(false);
    const [dataProtectionPopupVisible, setDataProtectionPopupVisible] = useState(false);

    const [uid, setUid] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const [pageNumber, setPageNumber] = useState(1);

    const [isInstallationRunning, setIsInstallationRunning] = useState(false);

    const [installationFailed, setInstallationFailed] = useState(false);

    const [userPreCheck, setUserPreCheck] = useState<UserPreCheckType | null>(null);


    useEffect(() => {
        setTermsPopupVisible(false);
        setDataProtectionPopupVisible(false);
        setUserVerified(false);
        setPageNumber(1);
        setInstallationFailed(false);
        setIsInstallationRunning(false);

        //logEvent(analytics, "page_view", {page_title: "Installation Wizard"});

    }, []);


    async function startInstallation(_uid: string) {
        setIsLoading(true)

        setIsInstallationRunning(true);

        const instData = new InstallationModel();

        instData.userId = _uid;
        instData.email = email;

        instData.firstName = firstName;
        instData.lastName = lastName;

        instData.clientLocationName = lastName;
        instData.customSenderName = "Pickadoc";

        let _modus = "normal" as "tradeshow" | "normal" | "basic" | "premium";

        switch (modus) {
            case "medicadeal":
                _modus = "tradeshow";
                break;

            case "basic":
                _modus = "basic";
                break;

            case "premium":
                _modus = "premium";
                break;

            default:
                _modus = "normal";
                break;
        }
        instData.modus = _modus;

        const installationSucceeded = await InstallationsService.startWizardInstallation(instData);

        if (installationSucceeded) {
            setIsInstallationRunning(false);

            setPageNumber(6);

            startRedirectTimer();

        } else {
            setInstallationFailed(true);
        }
    }

    function startRedirectTimer() {
        let countdown = 5;
        const intervalId = setInterval(() => {
            countdown -= 1;
            (document.getElementById("countdown") as any).innerText = countdown.toString();
            if (countdown <= 0) {
                clearInterval(intervalId);
                window.location.href = "https://cal.pickadoc.de/logout";
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }

    function isPageOneFilled() {

        let nameOkay = lastName && lastName.length > 1 && firstName && firstName.length > 1;

        let emailOkay = email && email.length > 5;

        let passwordOkay = password && password.length > 5;

        return emailOkay && passwordOkay && nameOkay;
    }

    function isPreviousButtonEnabled() {
        if (isLoading || isInstallationRunning) return false;

        if (userVerified && pageNumber <= 3) return false;

        if (pageNumber >= 5) return false;

        return true;
    }

    function isNextButtonEnabled() {

        if (pageNumber === 5) return false;

        if (isLoading || isInstallationRunning) return false;

        return isPageOneFilled();
    }

    function LoadingMessage() {
        if (isLoading) {
            return <div>
                <i className='fad fa-spinner fa-spin'></i>
            </div>
        }

        return <></>
    }

    function ErrorMessage() {
        if (errorMessage) {
            return <div className='kt-error-message'>{errorMessage}</div>
        }

        return <></>
    }

    async function registerNewUser(e) {

        e?.preventDefault();

        setErrorMessage(<></>);

        let hasError = false

        setIsLoading(true);

        let _uid = "";


        try {

            // first check if this email or phone number is already used
            const _userPreCheck = await UsersService.doesUserExists(email, "xxxxxxxxxxxxxxxxxxx");

            setUserPreCheck(_userPreCheck);

            if (!_userPreCheck) {
                setIsLoading(false);
                setErrorMessage(<>Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut.</>);
                return;
            }


            if (_userPreCheck.userHasLocationId) {
                setErrorMessage(<span>Sie Sind bereits bei uns registriert. Bitte loggen Sie Sich ein unter <a href="https://cal.pickadoc.de">https://cal.pickadoc.de</a></span>);
                hasError = true;
                setIsLoading(false);


            } else if (_userPreCheck?.emailUserExists) {

                // try to login email user
                const result = await signInWithEmailAndPassword(getAuth(), email, password);

                if (result && result.user && result.user.uid) {
                    setUid(result.user.uid);
                    _uid = result.user.uid;
                }

                setIsLoading(false);
                setErrorMessage(<></>);

            } else {

                // create an email user
                const result = await createUserWithEmailAndPassword(getAuth(), email, password);

                if (!result || !result.user || !result.user.uid) throw new Error("Es ist etwas schief gelaufen, bitte versuchen Sie es später noch einmal.");

                setUid(result.user.uid);

                _uid = result.user.uid;

                setIsLoading(false);

                setErrorMessage(<></>);

            }


        } catch (error) {

            getAuth().signOut();

            const errorCode = (error as any).code;

            hasError = true;

            switch (errorCode) {
                case "auth/email-already-in-use":
                case "auth/email-already-exists":
                    setErrorMessage(<span>Diese Email Adresse ist bereits registriert! Bitte benutzen Sie eine andere Email Adresse.</span>);
                    break;

                case "auth/invalid-email":
                    setErrorMessage(<span>Bitte geben Sie eine gültige Email Adresse an!</span>);
                    break;

                case "auth/invalid-password":
                case "auth/weak-password":
                    setErrorMessage(<span>Ihr Passwort ist zu schwach! Es muss mindestens 6 Zeichen lang sein.</span>);
                    break;

                case "auth/wrong-password":
                    setErrorMessage(<span>Diese Email Adresse ist bereits bei uns registriert,  aber das Passwort ist falsch! Bitte versuchen Sie es erneut.</span>);
                    break;

                case "auth/invalid-phone-number":
                    setErrorMessage(<span>Bitte geben Sie eine gültige Handynummer an!</span>);
                    break;

                default:
                    setErrorMessage(<span>{(error as any).message}</span>);
                    break;
            }
        }

        setIsLoading(false);

        if (!hasError) {
            //gotoNextPage(e);
            setPageNumber(5);
            startInstallation(_uid);
            //window.location.href = "https://cal.pickadoc.de/logout";
        }
    }



    function FirstPage() {

        return <form onSubmit={registerNewUser}>

            <div className='kt-wizard-page'>

                <p>
                    <strong>Sind Sie Arzt oder Ärztin?</strong>
                    <br />
                    Gewinnen Sie internationale Patienten mit KI-Videos in deren Sprachen. Testen Sie Pickadoc.
                    <br />
                </p>

                <div className="kt-form-ctrl">
                    <input name="name" type="text" value={firstName} onChange={e => setFirstName(e.currentTarget.value)} required placeholder='Vorname'></input>
                </div>

                <div className="kt-form-ctrl">
                    <input name="name" type="text" value={lastName} onChange={e => setLastName(e.currentTarget.value)} required placeholder='Nachname'></input>
                </div>

                <div className="kt-form-ctrl">
                    <input name="email" type="email" value={email} onChange={e => setEmail(e.currentTarget.value)} required placeholder='E-Mail'></input>
                </div>

                <div className="kt-form-ctrl">
                    <input name="password" type="password" value={password} onChange={e => setPassword(e.currentTarget.value)} required minLength={minPasswordLength} placeholder='Passwort'></input>
                </div>

                <div className="kt-form-ctrl">
                    <button name="submit" type="submit" className='kt-btn'>REGISTRIEREN</button>
                </div>

                <div className="kt-center kt-margin" style={{ fontSize: "14px" }}>
                    Sie haben schon einen Account? <a href="https://cal.pickadoc.de">Einloggen <i className="fas fa-external-link"></i></a>
                </div>

                <LoadingMessage />
                <ErrorMessage />

                <div className="kt-center kt-margin" style={{ fontSize: "14px" }}>
                    Durch die Anmeldung auf der Pickadoc-Plattform verstehen Sie unsere <span className="kt-link kt-underline" onClick={() => setTermsPopupVisible(true)}>AGBs</span> und <span className="kt-link kt-underline" onClick={() => setDataProtectionPopupVisible(true)}>Datenschutzrichtlinien</span> und stimmen diesen zu.
                </div>

            </div>


        </form>

    }


    return (
        <>
            <div></div>
            <div className="kt-installation-page">

                <div className='kt-registration-form'>
                    {/* <div className='kt-logo'><img src="images/logo-monochrome.svg" alt=""></img><span>Pick-A-Doc</span></div> */}

                    <div className='kt-logo-wrapper' style={{ marginTop: "10px" }}>
                        <img src='images/logo-monochrome.svg' alt='' width={50}></img>                        
                    </div>

                    {pageNumber < maxPages && <>
                        <h1>Willkommen bei Pickadoc</h1>
                    </>}

                    {pageNumber === 1 && FirstPage()}

                   
                    {pageNumber === 5 && <form>

                        {installationFailed ?
                            <div className='kt-wizard-page'>

                                <p>Es ist etwas schief gelaufen, bitte rufen Sie uns an, damit wir Ihnen bei der Installation weiterhelfen können.
                                </p>

                            </div>
                            :

                            <div className='kt-wizard-page'>

                                <p>Features und Pakete werden eingerichtet
                                </p>

                                <>
                                    <i className='fad fa-spinner fa-spin'></i>
                                    <br />
                                </>

                            </div>
                        }

                    </form>}


                    {pageNumber === 6 && <form>

                        <div className='kt-wizard-page'>

                            <p>
                                <h1>Einrichtung fertig</h1>
                                <br />
                                Sie erreichen Ihren Kalender unter
                            </p>

                            <a href="https://cal.pickadoc.de/logout" style={{ fontSize: "22px" }}>https://cal.pickadoc.de</a>

                            <p style={{ marginBottom: "50px" }}>Sie werden in <span id="countdown">{5}</span> Sekunden weitergeleitet.</p>

                        </div>

                    </form>}
                </div>


                <div className='kt-registration-bg'>
                    <div className='kt-logo-wrapper'>
                        <img src='images/logo-monochrome.svg' alt='' width={150}></img>
                        <div>Pick-A-Doc</div>
                        <div className='kt-sub-headline'>
                            <h1 style={{ margin: "60px 0 20px 0", lineHeight: "25px", textAlign: "center" }}>
                                Termin-Manager<br />
                                <span style={{ fontStyle: "italic" }}><img src="images/plus-icon-23581.png" width={20} alt="" />plus</span><br />
                                digitale Dokumente
                            </h1>
                        </div>
                    </div>
                    <div className='kt-footer'>
                        <span>Copyright © 2025 Pickadoc GmbH</span>
                    </div>
                </div>



            </div>

            <Popup visible={termsPopupVisible} onClosePopup={() => setTermsPopupVisible(false)}>
                <TermsPanel />
            </Popup>

            <Popup visible={dataProtectionPopupVisible} onClosePopup={() => setDataProtectionPopupVisible(false)}>
                <DataProtectionPanel />
            </Popup>
        </>
    );
}
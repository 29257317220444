import React, { useState, useEffect } from 'react';
import {
    Link
} from "react-router-dom";

import PriceDialog from '../../priceDialog';
import VideoSlider from '../../videoSlider';
// import { analytics } from '../../database';

export default function HomePage() {

    window.location.href = "https://pickadoc.ai";
    return <></>;

    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

    const [isPricePopUpVisible, setIsPricePopUpVisible] = useState(false);
    const [isInfoBoxVisible, setIsInfoBoxVisible] = useState(false);
    const [infoBoxContent, setInfoBoxContent] = useState(0);

    const [resolution, setResolution] = useState<"" | "qHD" | "HD" | "fullHD">("");

    function showInfoBox(contentIndex: number) {
        setInfoBoxContent(contentIndex);
        setIsInfoBoxVisible(true);
    }

    useEffect(() => {
        const innerWidth = window.innerWidth;

        if (innerWidth > 1280) {
            setResolution("fullHD");
        } else if (innerWidth > 960) {
            setResolution("HD");
        } else {
            setResolution("qHD");
        }

        // analytics.logEvent("page_view", {page_title: "Homepage"});
    }, [])


    return (

        <div className="kt-homePageNew">

            <PriceDialog isVisible={isPricePopUpVisible} setIsVisible={setIsPricePopUpVisible} />

            {isInfoBoxVisible &&
                <div className='kt-info-box' onClick={() => setIsInfoBoxVisible(false)}>
                    <div>

                        {infoBoxContent === 0 && <>
                            <p>Wir öffnen ihre Praxis an <strong>24 Stunden pro Tag</strong> - Ihre Kunden profitieren von einer persönlichen, benutzerfreundlichen Termingenerierung über Ihren Online-Terminplaner - ganz bequem vom Sofa aus. So können Ihre Kunden in Ruhe ihren Wunschtermin eintragen, aus verschiedenen Besuchsgründen wählen und sich auf Wunsch auch umfassend über die Behandlungsmöglichkeiten informieren.</p>

                            <p>Dabei bleiben ihre Termine <strong>vollautomatisiert in Erinnerung.</strong> Dafür sorgen wir mit Bestätigungs- und Erinnerungs-SMS, die individuell an die Bedürfnisse Ihrer Praxis angepasst werden. Das Besondere - wir ermöglichen Ihnen Ihren <strong>eigenen Absendernamen</strong> und natürlich sind auch die <strong>SMS-Texte individuell editierbar</strong>.</p>

                            <p>Aber wir bieten noch mehr - Ihre SMS enthält bereits für jeden Besuchsgrund passgenaue Aufklärungsvideos und auch die notwendigen <strong>Anamnese- und Aufklärungsdokumente</strong> werden vorab digital übermittelt. Unser P-Sign Modul ermöglicht es Ihren Patienten, diese Dokumente ganz bequem und in Ruhe von zu Hause aus auszufüllen und vorab an Sie zu übermitteln.</p>

                            <p>Darüberhinaus ermöglichen wir Ihnen personalisierte, <strong>passgenaue SMS-Kampagnen</strong> und machen Sie sichtbar präsent.</p>
                        </>}

                        {infoBoxContent === 1 && <>
                            <p>Sie möchten <strong>Terminlücken schließen</strong>, neue Praxisschwerpunkte veröffentlichen oder <strong>individuelle Grüße verschicken</strong>, z.B. zu Feiertagen?</p>

                            <p>Dann starten Sie mit uns Ihre <strong>SMS-Kampagne</strong> und sprechen Sie Ihre Patienten persönlich an.</p>

                            <p>Wir unterstützen Sie digital bei der Pflege Ihrer Bestandspatienten und <strong>schließen</strong> so <strong>Ihre Buchungslücken</strong>. Über unser SMS Tool lassen sich <strong>individuelle Werbe-Kampagnen</strong> erstellen und versenden. So erhalten Sie <strong>persönlichen Kontakt</strong> zu Ihren Patienten, können schnell und einfach neue Praxisschwerpunkte kommunizieren und Ihre Patienten effektiv und individuell ansprechen. Auch einzelne Patientengruppen lassen sich so <strong>passgenau informieren</strong>.</p>

                            <p>Sie möchten <strong>neue Patienten</strong> von sich als Arzt überzeugen? Ihnen Ihre Kompetenz live und in Farbe näher bringen oder Ihre Patienten ganz individuell und nahbar über die Behandlungsmöglichkeiten informieren?</p>

                            <p>Wir machen Sie <strong>sichtbar</strong> und stellen Sie Ihren Patienten <strong>persönlich</strong> vor. Mit unserem umfangreichen Video - Equipment erstellen wir auf Wunsch <strong>personalisierte Videos</strong>. So können Sie Ihren Patienten anstehende Behandlungen persönlich erklären oder Ihnen neue Behandlungsmöglichkeiten näherbringen. Sie erreichenn Ihre Patienten persönlich und überzeugen mit Transparenz und eigener Kompetenz. Die <strong>digitalen Videos</strong> lassen sich anschließend nach Ihren Wünschen einbinden - <strong>integriert in Ihren Buchungskalender</strong> oder anklickbar über SMS.</p >

                            <p>Dabei sind Ihrer Fantasie keine Grenzen gesetzt - Wir setzen Ihre Ideen nach Ihren Wünschen um.</p >

                            <p>Natürlich stehen aber auch passend zu den einzelnen Besuchgründen <strong>vorgefertigte Content</strong> zur Verfügung, die Ihre Patienten umfassend informieren und auf Ihren Praxisbesuch vorbereiten.</p >
                        </>}

                        {infoBoxContent === 2 && <>
                            <p>Wir sind mehr als ein <strong>Online-Buchungskalender</strong>. Das Plus - Ein <strong>integriertes Dokumentenmanagementsystem</strong>, das alle notwendigen Aufklärungs- und Anamnesedokumente Ihren Patienten vollautomatisch und passgenau zur Verfügung stellt. Sobald ein Patient einen Besuchsgrund auswählt und einen entsprechenden Termin über den Online-Kalender bucht, stellt das Programm fest, ob die <strong>erforderlichen Dokumente</strong> bereits vorliegen. Wenn Unterlagen fehlen, werden diese Anamnese- oder Aufklärungsbögen Ihrem Patienten umgehend digital zur Verfügung gestellt. Die dabei erstellte eindeutige Signatur macht unser Dokumentenmanagementsystem absolut <strong>fälschungssicher</strong>.</p>

                            <p>So kann Ihr Kunde alle erforderlichen Unterlagen bequem von zu Hause aus <strong>ausfüllen, digital unterschreiben und rückübermitteln</strong>. Und Sie behalten jederzeit den Überblick über die benötigten Unterlagen. Eine Win-Win-Situation, die auch noch nachhaltig papierlos funktioniert und in Ihrer Praxis vor Ort viel <strong>Zeit und Organisationsaufwand spart</strong>.</p>
                        </>}

                        {infoBoxContent === 3 && <>
                            <p>Wir bieten Ihnen <strong>Content aus der Praxis für die Praxis</strong>. Unser Produkt wurde von einem <strong>Zahnarzt</strong> mitentwickelt und basiert auf den <strong>langjährigen Erfahrungen</strong> des Praxis-Alltags einer Zahnarztpraxis. Daher bieten wir gerade Zahnärzten ein umfangreiches <strong>Content-Paket</strong> bestehend aus über <strong>90 Besuchsgründen</strong> mit passgenauen Aufklärungs- und Anamnesebögen, SMS und Email Vorlagen und individuellen Landingpages mit Erklär- und Aufklärungsvideos.</p>

                            <p>Auf Wunsch bieten wir Ihnen auch die Möglichkeit die Texte, Bilder und <strong>Videos</strong> individuell anzupassen. So können Sie mit Ihren Patienten über Ihre individuell angefertigten Videos <strong>persönlich in Kontakt treten</strong>.</p>
                        </>}

                        {infoBoxContent === 4 && <>
                            <p>Wir arbeiten <strong>browserbasiert</strong>. Sie können von überall auf der Welt auf ihre Termine <strong>zugreifen</strong> und <strong>sparen</strong> sich kostenintensive <strong>Installationen und Wartungskosten</strong>. So bleiben sie immer automatisch up-to-date.</p>

                            <p>Unser <strong>Live Bot</strong> ermöglicht Ihnen dabei online erstellte Termine, Terminabsagen, neu ausgefüllte Dokumente und neue Bewertungen in <strong>Echtzeit</strong> zu verfolgen.</p>

                            <p>Sie behalten über das benutzerfreundliche <strong>Dashboard</strong> alle Ereignisse im Blick und haben die volle Kontrolle über ihr Termin-Management und Ihre <strong>Patienten-Bewertungen</strong>.</p>
                        </>}

                        {infoBoxContent === 5 && <>
                            <p>Testen Sie <strong>noch heute</strong> unser Können und entdecken Sie Ihren Mehrwert. <strong>Registrieren Sie sich kostenlos</strong>. Sie können Ihren Online-Buchungskalender in Ruhe zu Hause persönlich einrichten und <strong>alle Funktionen testen</strong>. Auch die <strong>ersten 30 Tage</strong> sind für Sie <strong>kostenlos</strong>.</p>

                            <p>Natürlich stehen wir Ihnen <strong>jederzeit</strong> auch <strong>persönlich</strong> zur Verfügung. <strong>Kontaktieren Sie uns</strong>!</p>
                        </>}

                        <div className='kt-btn' onClick={() => setIsInfoBoxVisible(false)}><div>SCHLIEßEN</div></div>
                    </div >
                </div >
            }


            <div className="kt-header">

                <div className="kt-header-wrapper">
                    <div className='kt-logo-wrapper'>
                        <a href="https://pickadoc.de"><img src="/images/logo-monochrome.svg" width={55} alt="logo" /></a>
                        <a href="https://pickadoc.de"><span className='kt-logo-text'>Pickadoc</span></a>
                    </div>

                    <nav
                        className={isMobileMenuVisible ? "kt-mobile-menu" : ""}
                        onClick={() => setIsMobileMenuVisible(false)}
                    >
                        <ul>
                            <li><a href="#appointments">Recaller</a></li>
                            <li><a href="#psign">p-sign</a></li>
                            <li><a href="#content_pack">Content Pack</a></li>
                            <li><a href="#livebot">Live Bot</a></li>
                            <li><a href="#prices">Preise</a></li>
                            <li className='kt-mobile'><a href="https://cal.pickadoc.de">Login</a></li>
                        </ul>
                    </nav>

                    <div className="kt-right-column">
                        <a href="https://cal.pickadoc.de" className='kt-desktop'>Login</a>
                        <a href="/installation" className='kt-btn kt-desktop'><span>REGISTRIEREN</span></a>
                        <i
                            className='fas fa-bars kt-mobile'
                            onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
                        ></i>
                    </div>
                </div>
            </div>

            <div className='kt-home-body'>
                <div className='kt-box-row-wrapper'>

                    <div className='kt-box-row kt-box-features' style={{margin: 0}}>
                        <div className='kt-box-row-content kt-big' style={{width: "100%", maxWidth: "100%", flexDirection: "column"}}>

                            <div className='kt-box-image-slider' style={{margin: 0}}>
                                <VideoSlider />
                            </div>

                            {/* <div className='kt-box-image' onClick={startVideo}>

                                {resolution === "fullHD" && <video id="product-video" className="kt-fade-in" src="videos/PickADoc-fullHD.mp4" poster='images/duo-devices-play.png' playsInline></video>}
                                {resolution === "HD" && <video id="product-video" className="kt-fade-in" src="videos/PickADoc-HD.mp4" poster='images/duo-devices-play.png' playsInline></video>}
                                {resolution === "qHD" && <video id="product-video" className="kt-fade-in" src="videos/PickADoc-qHD.mp4" poster='images/duo-devices-play.png' playsInline></video>}
                            </div> */}

                            <div className='kt-box-text kt-mobile-center' style={{display:"flex", alignItems: "center", flexDirection: "column", margin: "20px 0"}}>
                                <h1 style={{ margin: "20px 0 20px 0", lineHeight: "45px", textAlign: "center" }}>
                                    Termin-Manager<br />
                                    <span style={{ fontStyle: "italic", fontWeight: 900 }}><img src="/images/plus-icon-23581.png" width={25} alt="" />plus</span><br />
                                    digitale Dokumente
                                </h1>

                                <ul className="kt-main-features">
                                    <li><strong><img src="/images/plus-icon-23581.png" alt="" /> up-to-date.</strong><br />Termingenerierung und Recalls</li>
                                    <li><strong><img src="/images/plus-icon-23581.png" alt="" /> präsent.</strong><br />SMS und Video-Marketing</li>
                                    <li><strong><img src="/images/plus-icon-23581.png" alt="" /> smart.</strong><br />Digitale Aufklärungsdokumente</li>
                                    <li><strong><img src="/images/plus-icon-23581.png" alt="" /> transparent.</strong><br />Kostenkontrolle in Echtzeit</li>
                                </ul>
                                <br />
                                <a href="/installation" className='kt-btn'><span>KOSTENLOS REGISTRIEREN</span></a>
                            </div>

                        </div>
                    </div>



                    <div className="anchor" id="appointments"></div>
                    <div className='kt-box-row kt-box-row-reverse kt-no-margin-bottom'>
                        <div className='kt-box-row-content kt-feature-details'>
                            <div className='kt-box-text'>
                                <h1>
                                    <img src="/images/plus-icon-23581.png" alt="" /> up-to-date.
                                </h1>
                                <h2>Termingenerierung und Recalls</h2>
                                <ul>
                                    <li>Termin-Bestätigungs und Erinnerungs-SMS Versand</li>
                                    <li>Vollautomatische Recalltermine, hohe Konversion dank K.I.</li>
                                    <li>Ihr eigenes Corporate Design: persönlich, editierbar </li>
                                    <li>Multistandortfähig</li>
                                    <li>Unbegrenzt viele Behandler</li>
                                    <li>Callcenter– und SMS Recall-Kampagnen </li>
                                    <li>keine monatlichen Fixkosten</li>
                                </ul>
                            </div>
                            <div className='kt-more-info-wrapper'>
                                <div className='kt-box-image' style={{ marginTop: "-30px" }}>
                                    <img src="/images/recaller-gear-white.svg" alt="Recaller - Vollautomatische Termingenerierung" />
                                </div>
                                <div className='kt-btn' onClick={() => showInfoBox(0)}><div>MEHR INFOS</div></div>
                            </div>
                        </div>
                    </div>


                    <div className="anchor" id=""></div>
                    <div className='kt-box-row'>
                        <div className='kt-box-row-content kt-feature-details'>
                            <div className='kt-box-text'>
                                <h1>
                                    <img src="/images/plus-icon-23581.png" alt="" /> präsent.
                                </h1>
                                <h2>SMS und Video-Marketing</h2>
                                <ul>
                                    <li>Patientenkommunikation auf allen Ebenen</li>
                                    <li>SMS, Telefon und Email</li>
                                    <li>Landingpages für jeden Termin mit vorgefertigten Texten</li>
                                    <li>Einbindung von Erklär-und Präsentationsvideos</li>
                                    <li>Alle Besuchsgründe mit Recallalgorithmen eingerichtet zum direkten Durchstarten</li>
                                </ul>
                            </div>
                            <div className='kt-more-info-wrapper'>
                                <div className='kt-box-image'>
                                    <img src="/images/sms-doctor.png" alt="SMS Online Kalender" />
                                </div>
                                <div className='kt-btn' onClick={() => showInfoBox(1)}><div>MEHR INFOS</div></div>
                            </div>
                        </div>
                    </div>


                    <div className="anchor" id="psign"></div>
                    <div className='kt-box-row kt-box-row-reverse'>
                        <div className='kt-box-row-content kt-feature-details'>
                            <div className='kt-box-text'>
                                <h1>
                                    <img src="/images/plus-icon-23581.png" alt="" /> smart.
                                </h1>
                                <h2>2 in 1 - Termine mit rechtssicheren Dokumenten</h2>

                                <ul>
                                    <li>Vorgefertigte digitale Anamnese und Aufklärungsbögen</li>
                                    <li>Vorgefertigte Zuordnung der Dokumente zu den Besuchsgründen</li>
                                    <li>Automatischer Dokumenten-Versand bei Terminbuchung</li>
                                    <li>Ausfüllen auf Praxis Ipad oder Patienten Endgeräten mittels QR-Code</li>
                                    <li>Digitale Patienten-Unterschrift und sichere Archivierung digital signierter PDFs</li>
                                    <li>Video-Aufklärungsgespräche</li>
                                    <li>Einfache Rückübermittlung an Ihre Praxis per Knopfdruck</li>
                                    <li>Nachhaltig und papierlos</li>
                                    <li>Datenschutzkonform</li>
                                </ul>

                            </div>
                            <div className='kt-more-info-wrapper'>
                                <div className='kt-box-image' style={{ marginTop: "-30px" }}>
                                    <img src="/images/p-sign-white.svg" alt="Recaller - Vollautomatische Termingenerierung" />
                                </div>
                                <div className='kt-btn' onClick={() => showInfoBox(2)}><div>MEHR INFOS</div></div>
                            </div>
                        </div>
                    </div>

                    <div className="anchor" id="content_pack"></div>
                    <div className='kt-box-row'>
                        <div className='kt-box-row-content kt-feature-details'>
                            <div className='kt-box-text'>
                                <h1>
                                    <img src="/images/plus-icon-23581.png" alt="" /> persönlich.
                                </h1>
                                <h2>Vorkonfigurierter Content für Zahnärzte</h2>

                                <ul>
                                    <li>Alle SMS in Ihrem Absendenamen</li>
                                    <li>90 Besuchsgründe mit über 90 Landinpages</li>
                                    <li>50 verschiedene SMS-Patientenansprachen</li>
                                    <li>30 parallel greifende Recallalgorithmen.</li>
                                    <li>Über 15 fertige Aufklärungsbögen.</li>
                                    <li>Alle Inhalte sind editierbar</li>
                                </ul>
                            </div>
                            <div className='kt-more-info-wrapper'>
                                <div className='kt-box-image'>
                                    <img src="/images/struktur01.svg" alt="SMARTE INTEGRATION DIGITALER DOKUMENTE" />
                                </div>
                                <div className='kt-btn' onClick={() => showInfoBox(3)}><div>MEHR INFOS</div></div>
                            </div>
                        </div>
                    </div>


                    <div className="anchor" id="livebot"></div>
                    <div className='kt-box-row kt-box-row-reverse'>
                        <div className='kt-box-row-content kt-feature-details'>
                            <div className='kt-box-text'>
                                <h1>
                                    <img src="/images/plus-icon-23581.png" alt="" /> funktional.
                                </h1>
                                <h2>LiveBot und Dashboard</h2>

                                <ul>
                                    <li>Livebot Realtime Report- alle Ereignisse live verfolgen</li>
                                    <li>LiveBot K.I. generiert zukünftige Termine</li>
                                    <li>Überwachung von fehlenden oder abgelaufennen Patienten Dokumenten</li>
                                    <li>Vollautomatischer Empfang von Bewertungen Ihrer Patienten</li>
                                    <li>Volle Kontrolle: alle Bewertungen publizierbar, oder löschbar</li>
                                    <li>Integriertes Behandler-Ranking auf Basis der Patienten Bewertungen</li>
                                </ul>
                            </div>
                            <div className='kt-more-info-wrapper'>
                                <div className='kt-box-image' style={{ marginTop: "-30px" }}>
                                    <img src="/images/livebot-white.svg" alt="Live-Bot Verfolgung und Dashboard Darstellung" />
                                </div>
                                <div className='kt-btn' onClick={() => showInfoBox(4)}><div>MEHR INFOS</div></div>
                            </div>
                        </div>
                    </div>


                    <div className="anchor" id="prices"></div>
                    <div className='kt-box-row'>
                        <div className='kt-box-row-content kt-feature-details'>
                            <div className='kt-box-text'>
                                <h1>
                                    <img src="/images/plus-icon-23581.png" alt="" /> transparent.
                                </h1>
                                <h2>Kostenkontrolle in Echtzeit</h2>

                                <ul>
                                    <li>0€ monatliche Fixkosten</li>
                                    <li>Transparentes Pay-Per-Use</li>
                                    <li>Konstenkontrolle in Realtime</li>
                                    <li>Performance und Kostenlimits einstellbar</li>
                                </ul>
                            </div>
                            <div className='kt-more-info-wrapper'>
                                <div className='kt-box-image' style={{ marginTop: "-20px" }}>
                                    <img src="/images/no-fixed-cost.png" alt="Keine Fixkosten" />
                                </div>
                                <div className='kt-btn' onClick={() => showInfoBox(5)}><div>MEHR INFOS</div></div>
                            </div>
                        </div>
                    </div>

                    <div className='kt-box-row kt-no-margin-bottom kt-box-row-reverse'>
                        <div className='kt-box-row-content' style={{ display: "flex", alignItems: "center", minHeight: "100px" }}>

                            <h2>Legen Sie sofort los <br /> Startklar in 5 Minuten</h2>

                            <div className='kt-text-button-row'>
                                <a href="/installation" className='kt-btn' style={{ margin: "30px 0" }}><span>KOSTENLOS REGISTRIEREN</span></a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className='kt-footer'>
                <div className='kt-footer-wrapper'>
                    <div className='kt-logo-wrapper'>
                        <img src="/images/logo-white.svg" width={32} alt="logo" />
                        <span className='kt-logo-text'>Pickadoc</span>
                    </div>
                    <div className='kt-footer-links'>
                        <Link to={`/contact`}>Kontakt</Link>
                        •
                        <Link to={`/terms`}>AGB</Link>
                        •
                        <Link to={`/legal-notice`}>Impressum</Link>
                        •
                        <Link to={`/data-protection`}>Datenschutz</Link>
                        •
                        <a href="https://www.instagram.com/pickadoc.ai/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" style={{fontSize:"20px"}}></i></a>
                    </div>
                    <div>
                        &copy; 2025 Pickadoc GmbH
                    </div>
                </div>
            </div>


        </div >
    );
}
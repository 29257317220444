import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import CalendarsService from '../../../services/calendarsService';

const steps = ['Start', 'Email', 'Fertig'];

export default function CalendrPlaygroundPage() {

    const [activeStep, setActiveStep] = useState(0);

    const [clientName, setClientName] = useState("");
    const [visitMotiveText, setVisitMotiveText] = useState("");
    const [email, setEmail] = useState("");

    const [clientNameError, setClientNameError] = useState("");
    const [visitMotiveTextError, setVisitMotiveTextError] = useState("");
    const [emailError, setEmailError] = useState("");

    useEffect(() => {

        if (clientName.length < 2) {
            setClientNameError("Bitte geben Sie mindestens 2 Zeichen ein.")
        } else {
            setClientNameError("");
        }

    }, [clientName]);

    useEffect(() => {

        if (visitMotiveText.length < 5) {
            setVisitMotiveTextError("Bitte geben Sie mindestens 5 Zeichen ein.")
        } else {
            setVisitMotiveTextError("");
        }

    }, [visitMotiveText]);

    useEffect(() => {

        if (email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setEmailError("");
        } else {
            setEmailError("Bitte geben Sie ein gültige Emailadresse ein.");
        }

    }, [email]);


    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const resetAll = () => {
        setActiveStep(0);

        setClientName("");
        setClientNameError("");

        setEmail("");
        setVisitMotiveText("");

        setEmailError("");
        setVisitMotiveTextError("");
    }

    const handleGenerateLandingPage = async () => {

        CalendarsService.generatePlaygroundLandingPage(clientName, visitMotiveText, email);

        setActiveStep(2);
    };


    return (

        <div className="kt-playgroundPage kt-calendr-playgroundPage">

            <Dialog
                open={true}
                maxWidth="md"
                fullWidth
                className='kt-playgroundPage-dialog'
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                        ".MuiPaper-root": { borderRadius: "20px !important" }
                    }
                }}
            >
                <DialogTitle style={{ display: "flex", alignItems: "center" }}>
                    <img src="/images/logo.svg" width={25} alt="logo" />&nbsp;Landingpage Playground
                </DialogTitle>

                <DialogContent>
                    <Box className='kt-text-secondary' mb={1}>Next Level Patientenkommunikation</Box>
                    <Box sx={{ width: '100%' }}>
                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={activeStep > index}>
                                    <StepButton color="inherit">
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>

                        <Box mt={2} style={{ minHeight: "400px" }}>
                            {activeStep === 0 &&
                                <React.Fragment>

                                    <Typography sx={{ mt: 4 }}>
                                        <strong>Legen Sie sofort los und erstellen Sie Ihre persöhnliche AI generierte Praxis Landingpage.</strong>
                                        <br />
                                        Wir erstellen Ihnen vollautomatisch Landingpages für all Ihre Besuchsgründe, mit Text und Video in 30 Sprachen!
                                        <br />
                                    </Typography>

                                    <Box textAlign="center" mt={2}>
                                        <img src="/images/playgrounds/lp-thumbnail.jpg" style={{ maxHeight: "300px", maxWidth: "100%" }} />
                                    </Box>

                                    <Grid item xs={12} mt={4}>
                                        <Typography sx={{ mt: 4, mb: 1 }}>
                                            Geben Sie hier den Besuchsgrund an. (z.B. Zahnaufhellung, Infiltration Knie Eigenblut):
                                        </Typography>
                                        <TextField
                                            label="Besuchsgrund"
                                            variant='outlined'
                                            required type="text"
                                            fullWidth
                                            value={visitMotiveText}
                                            onChange={e => setVisitMotiveText(e.currentTarget.value)}
                                            inputProps={{ minLength: 5, maxLength: 200 }}
                                            error={visitMotiveTextError !== ""}
                                            helperText={visitMotiveTextError}
                                        />
                                    </Grid>


                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", pt: 2 }}>

                                        <Button onClick={handleNext} sx={{ mr: 1 }} variant='contained' size="large" color='secondary'
                                            disabled={visitMotiveText === "" || visitMotiveTextError !== ""}
                                        >
                                            Weiter
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            }

                            {activeStep === 1 &&
                                <React.Fragment>

                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Typography sx={{ mt: 4, mb: 1 }}>
                                                Geben Sie hier Ihren Praxisnamen an, der auf der Landingpage erscheinen soll:
                                            </Typography>
                                            <TextField
                                                label="Name"
                                                variant="outlined"
                                                required
                                                type='text'
                                                fullWidth
                                                value={clientName}
                                                onChange={e => setClientName(e.currentTarget.value)}
                                                error={clientNameError !== ""}
                                                helperText={clientNameError}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography sx={{ mt: 4, mb: 1 }}>
                                                Ihre Email an die wir die fertige Landingpage senden können.
                                            </Typography>
                                            <TextField
                                                label="Email"
                                                variant="outlined"
                                                required
                                                type='email'
                                                fullWidth
                                                value={email}
                                                onChange={e => setEmail(e.currentTarget.value)}
                                                error={emailError !== ""}
                                                helperText={emailError}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Zurück
                                        </Button>
                                        <Button onClick={handleGenerateLandingPage} sx={{ mr: 1 }} variant='contained' size='large' color='secondary'
                                            disabled={email === "" || emailError !== "" || clientNameError !== ""}
                                        >
                                            Landingpage erstellen
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            }

                            {activeStep === 2 &&
                                <React.Fragment>

                                    <Box textAlign={"center"} pt={3}>

                                        <Typography color={"grey"}>
                                            <i className="far fa-mailbox" style={{ fontSize: "120px" }}></i>
                                        </Typography>
                                        <br />
                                        <Typography>
                                            Das war's schon. Ihre persöhnliche AI Praxis Landingpage wird nun generiert und ist in wenigen Minuten fertig.
                                        </Typography>
                                        <br />
                                        <Typography>
                                            Sobald Ihre Landingpage erstellt ist, erhalten Sie eine Email an {email} mit dem Link.
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", pt: 2 }}>

                                        <Button onClick={resetAll} sx={{ mr: 1 }} variant='contained' size="large" color='secondary'
                                        >
                                            Neu starten
                                        </Button>
                                    </Box>

                                </React.Fragment>
                            }

                        </Box>

                    </Box>

                    <Typography sx={{ mt: 2, textAlign: "center" }}>
                        © 2025 Pickadoc GmbH
                    </Typography>

                </DialogContent>
            </Dialog>

        </div >
    );
}
import React, {useEffect} from 'react';

import {scrollToTop} from "../../utils";
import DataProtectionPanel from '../dataProtectionPanel';
// import { analytics } from '../database';

export default function DataProtectionPage() {

    // useEffect(()=>{
    //     analytics.logEvent("page_view", {page_title: "Legal Notice"});
    // },[]);

    useEffect(() => {
        scrollToTop();
    });


    return (
        <div className="kt-contactPage">

            <div className="kt-body">
                <div className="kt-panel">

                    <div className="kt-content-header">

                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>

                        <DataProtectionPanel />
                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>

                    </div>
                </div>
            </div>

        </div>
    );
}
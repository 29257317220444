import React, { useState, useEffect } from 'react';

import moment from 'moment';

import Avatar from "./avatar";

import AppointmentsService from "../services/appointmentsService";
import VisitMotivesService from "../services/visitMotivesService";

import FreeTimeSlotsCtrl from './freeTimeSlotsCtrl';
import PublicHolidaysService from '../services/publicHolidaysService';
import LoadingCtrl from './loadingCtrl';

interface Props {
    client: any;
    location: any;
    doctor: any;
    doctors: any[];
    appointment?: any; // in case patient just wants to change the date and/or confirms the appointment
    visitMotive?: any; // in case patient just wants to change the date and/or confirms the appointment
    bookingMode?: "create" | "postpone"
}

const BookingPanel: React.FC<Props> = ({ client, location, doctor, doctors, appointment, visitMotive, bookingMode }) => {


    const [bookingPanelVisible, setBookingPanelVisible] = useState(false);

    const [currentDate, setCurrentDate] = useState(moment().startOf('week'));
    const [lastDate, setLastDate] = useState<moment.Moment | null>(null);

    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

    const [appointments, setAppointments] = useState<any[]>([]);

    const [publicHolidays, setPublicHolidays] = useState<any[]>([]);

    const [visitMotives, setVisitMotives] = useState<any[]>([]);
    const [selectedVisitMotiveId, setSelectedVisitMotiveId] = useState("-1");

    const [selectedVisitMotive, setSelectedVisitMotive] = useState<any>(null);

    const [selectedDoctor, setSelectedDoctor] = useState<any>(null);

    const [isBooking, setIsBooking] = useState(false);
    const [isLoadingAppointments, setIsLoadingAppointments] = useState(false);

    const [appointmentsRequestStartDate, setAppointmentsRequestStartDate] = useState<Date | null>(null);
    const [appointmentsRequestEndDate, setAppointmentsRequestEndDate] = useState<Date | null>(null);

    const [lastCalendarIdsString, setLastCalendarIdsString] = useState("");

    useEffect(() => {

        async function updateVisitMotive() {
            if (appointment) {
                setSelectedVisitMotiveId(appointment.visitMotive.id);

                const vMotive = await VisitMotivesService.getVisitMotive(appointment.visitMotive.id, client.id, location.id);

                setSelectedVisitMotive(vMotive);
            }
        }

        if (appointment && (!visitMotive || appointment.visitMotive.id !== visitMotive.id)) {
            updateVisitMotive();
        } else if (visitMotive) {
            setSelectedVisitMotiveId(visitMotive.id);
            setSelectedVisitMotive(visitMotive);
        }

    }, [appointment]);


    useEffect(() => {


        // if (selectedVisitMotiveId === "-1") {
        //     return;
        // }

        updateAppointments(currentDate);

    }, [client, doctor, currentDate, selectedDoctor, selectedVisitMotiveId, appointment]);


    useEffect(() => {
        async function updatePublicHolidays() {
            if (currentDate) {
                const numberOfYears = currentDate.month() >= 10 ? 2 : 1;  // get also public holidays of next year
                const holidays = await PublicHolidaysService.getPublicHolidays(client.country, client.state, currentDate.year(), numberOfYears);

                if (holidays) setPublicHolidays(holidays);
            }
        }

        updatePublicHolidays();

    }, [client, currentDate])

    useEffect(() => {

        // if we only update an appointment we do not need to load all visit motives
        if (appointment) return;

        function updateVisitMotives() {

            if (location) {
                VisitMotivesService.getOnlineVisitMotives(client.id, location.id)
                    .then((motives) => {

                        if (motives) {
                            motives.unshift({
                                id: "-1",
                                name: "Bitte Terminart wählen"
                            });

                            setVisitMotives(motives);
                            if (motives && motives.length > 0) {
                                setSelectedVisitMotiveId(motives[0].id);
                            }
                        }

                    })
            }
        }

        updateVisitMotives();
    }, [doctor, location]);

    useEffect(() => {

        setIsBooking(false);
        setBookingPanelVisible(false);
        setIsLoadingAppointments(false);

    }, []);

    useEffect(() => {
        setSelectedDoctor(null);
    }, [doctor, client]);


    async function updateAppointments(_currentDate: moment.Moment) {

        //console.log(`updateAppointments doctor:`, doctor);
        //console.log(`updateAppointments selectedDoctor:`, selectedDoctor);

        //console.log(`_currentDate: ${_currentDate} currentDate: ${currentDate} lastDate: ${lastDate}`);

        let calendarIds = [];

        if (doctor && doctor.calendarIds) {
            calendarIds = doctor.calendarIds;
        } else if (selectedDoctor && selectedDoctor.calendarIds) {
            calendarIds = selectedDoctor.calendarIds;
        }

        // we always need a calendar id to search for appointments
        if (calendarIds.length === 0) {
            setLastCalendarIdsString("");
            return;
        }

        // store used calendar ids to check next time if query has changed
        const currentCalendarIdsString = JSON.stringify(calendarIds);
        setLastCalendarIdsString(currentCalendarIdsString);


        //console.log("calendarIds: ", calendarIds);


        if (client && client.id && (!_currentDate.isSame(lastDate) || !_currentDate.isSame(currentDate) || lastCalendarIdsString === "" || lastCalendarIdsString !== currentCalendarIdsString)) {


            const startDate = moment(_currentDate).subtract(60, "day").toDate();
            const endDate = moment(_currentDate).add(180, "day").toDate();

            setAppointmentsRequestStartDate(startDate);
            setAppointmentsRequestEndDate(endDate);

            setLastDate(moment(_currentDate));
            setCurrentDate(moment(_currentDate));


            if (location) {

                setIsLoadingAppointments(true);

                try {

                    const appointments = await AppointmentsService.getPublicAppointments(client.id, location.id, startDate, endDate, calendarIds);

                    if (appointments) {
                        //console.log(`${appointments.length} public appointments found`);
                        setAppointments(appointments);
                    }

                } catch (ex) {
                    console.error(ex);
                }

                setIsLoadingAppointments(false);

            }
        }
    }



    function onVisitMotiveChange(event) {
        const newMotiveId = event.target.value;
        setSelectedVisitMotiveId(newMotiveId);

        setSelectedDoctor(null);
    }

    function onDoctorClick(_doctor) {
        setSelectedDoctor(_doctor);
    }







    function renderDoctors() {
        function getUserClassName(doctor) {
            return (selectedDoctor && selectedDoctor.id === doctor.id) ? "kt-avatar-columns kt-selected-user" : "kt-avatar-columns";
        }

        if (doctors && doctors.length > 0) {

            const selectedMotive = visitMotives.find(m => m.id === selectedVisitMotiveId);

            return doctors.map((doctor) =>
                (!selectedMotive || !selectedMotive.dissabledFor || !selectedMotive.dissabledFor.includes(doctor.id)) &&
                <div key={doctor.id} className={getUserClassName(doctor)} onClick={() => onDoctorClick(doctor)}>
                    <Avatar src={doctor.avatarUrl} alt="" width="50px" />
                    <div>{doctor.title} {doctor.firstName} {doctor.lastName}</div>
                </div>
            )
        } else {
            return <div></div>
        }
    }

    function renderVisitMotivePatientInfo() {
        const selectedMotive = visitMotives.find(m => m.id === selectedVisitMotiveId);

        if (selectedMotive) {
            if (selectedMotive.videoLink) {

                return <div className="kt-video-wrapper">
                    <iframe
                        title='patient infos'
                        className="kt-video"
                        width="280"
                        height="200"
                        src={selectedMotive.videoLink}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    >
                    </iframe>
                </div>

            } else if (selectedMotive.patientInfo) {
                return <div className="kt-patient-info">{selectedMotive.patientInfo}</div>;
            }
        }
    }

    function getMinDate(): Date | null | undefined {

        // if patient postpones an appointment, then the next free booking date will be the next day 1am (if open)
        if (bookingMode && bookingMode === "postpone") {
            const nextDay = new Date();
            nextDay.setDate(nextDay.getDate() + 1);
            nextDay.setHours(1, 0, 0);
            return nextDay;
        }

        return appointment ? appointment.start : null;
    }


    const handleCurrentDateChange = (newCurrentDate: Date) => {

        // check if newCurrentDate is within our date range for which we requested our appointments
        if (appointmentsRequestStartDate && appointmentsRequestEndDate &&
            (newCurrentDate.getTime() <= appointmentsRequestStartDate.getTime() || newCurrentDate.getTime() >= appointmentsRequestEndDate.getTime())) {
            updateAppointments(moment(newCurrentDate));
        }

    }


    if (isBooking) {
        return (
            <LoadingCtrl show={true} message="Ihr Termin wird nun für Sie reserviert..."></LoadingCtrl>
        );
    }

    //console.log("rendering booking panel");

    // if ((doctor && doctor.allowOnlineAppointments === false) || (selectedDoctor && selectedDoctor.allowOnlineAppointments === false)) {
    //     return <></>;
    // }

    return (
        <div className="kt-bookingPanel noselect">

            <div className="kt-btn-big kt-btn-secondary-color" onClick={() => setBookingPanelVisible(!bookingPanelVisible)}>
                <i className="fal fa-calendar-alt" aria-hidden="true"></i>
                {(bookingMode && bookingMode === "postpone") ? 
                    <span>Termin verschieben</span> 
                    : 
                    <span>{location.customBookingButtonText ? location.customBookingButtonText : "Termin online buchen"}</span>
                }
            </div>

            {bookingPanelVisible &&
                <div className="kt-panel kt-expand-bottom">

                    <div className="kt-panel-icon"></div>

                    <div className="kt-bookingPanel-content">

                        <div className="kt-panel kt-with-icon kt-bookingStep">

                            <div className="kt-progressColumn">
                                <div className={selectedVisitMotiveId !== "-1" ? "kt-booking-progress-icon kt-booking-step-selected" : "kt-booking-progress-icon"}>
                                    1
                                </div>
                                <div className={selectedVisitMotiveId !== "-1" ? "kt-bookingStep-line kt-booking-step-selected" : "kt-bookingStep-line"}></div>
                            </div>

                            <div className="kt-progressColumn">
                                <h3>Terminart:</h3>
                                {selectedVisitMotive === null ?
                                    <>
                                        <select name="visitMotive" value={selectedVisitMotiveId} onChange={onVisitMotiveChange}>
                                            {visitMotives.map((motive) =>
                                                <option key={motive.id} value={motive.id}>{motive.nameForPatient ? motive.nameForPatient : motive.name}</option>
                                            )}
                                        </select>

                                        {renderVisitMotivePatientInfo()}
                                    </>
                                    :
                                    <div>{selectedVisitMotive.nameForPatient ? selectedVisitMotive.nameForPatient : selectedVisitMotive.name}</div>
                                }
                            </div>
                        </div>

                        {(!doctor && selectedVisitMotiveId !== "-1") &&
                            <div className="kt-panel kt-with-icon kt-bookingStep">

                                <div className="kt-progressColumn">
                                    <div className={((doctor || selectedDoctor) && selectedVisitMotiveId !== "-1") ? "kt-booking-progress-icon kt-booking-step-selected" : "kt-booking-progress-icon"}>
                                        2
                                    </div>
                                    <div className={((doctor || selectedDoctor) && selectedVisitMotiveId !== "-1") ? "kt-bookingStep-line kt-booking-step-selected" : "kt-bookingStep-line"}></div>
                                </div>

                                <div className="kt-progressColumn">
                                    <h3>{location.customDoctorDesignation ? location.customDoctorDesignation : "Arzt:"}</h3>
                                    {renderDoctors()}
                                </div>
                            </div>
                        }


                        {((doctor || selectedDoctor) && selectedVisitMotiveId !== "-1") &&
                            <div className="kt-panel kt-with-icon kt-bookingStep">

                                <div className="kt-progressColumn">
                                    <div className={selectedTimeSlot ? "kt-booking-progress-icon kt-booking-step-selected" : "kt-booking-progress-icon"}>
                                        <div>{doctor ? "2" : "3"}</div>
                                    </div>
                                </div>

                                <div className="kt-progressColumn">
                                    <h3>Termin wählen:</h3>

                                    <FreeTimeSlotsCtrl
                                        isLoading={isLoadingAppointments}
                                        doctor={selectedDoctor ?? doctor}
                                        appointment={appointment}
                                        appointments={appointments}
                                        publicHolidays={publicHolidays}
                                        visitMotiveId={selectedVisitMotiveId}
                                        visitMotives={visitMotives}
                                        locationId={location.id}
                                        onIsBookingChange={newState => setIsBooking(newState)}
                                        minDate={getMinDate()}
                                        locationOpeningHours={location.openingHours}
                                        onCurrentDateChange={handleCurrentDateChange}
                                    />


                                </div>
                            </div>
                        }

                    </div>
                </div>

            }

        </div>
    );
}

export default BookingPanel;
import React, { useState, useEffect } from 'react';
import {
    Link
} from "react-router-dom";

import axios from 'axios';
import AudioRecorder, { AudioRecorderStatus } from '../../AudioRecorder';
import VoicePlayback from './voicePlayback';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import { useConversation } from '@11labs/react';


export default function CallRPlaygroundPage() {

    const conversation = useConversation();

    const [callIsActive, setCallIsActive] = useState(false);

    useEffect(() => {
    }, [])

    async function startCall() {

        // call after explaning to the user why the microphone access is needed
        await navigator.mediaDevices.getUserMedia({ audio: true });

        const conversationId = await conversation.startSession(
            {
                agentId: "pSUVNVsczM4risVkBNAt",
                onConnect: () => setCallIsActive(true)
            }
        );
    }

    function stopCall() {
        conversation.endSession();

        setCallIsActive(false);
    }

    return (
        <div className="kt-playgroundPage kt-calendr-playgroundPage">

            <Dialog
                open={true}
                maxWidth="md"
                fullWidth
                className='kt-playgroundPage-dialog'
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                        ".MuiPaper-root": { borderRadius: "20px !important" }
                    }
                }}
            >
                <DialogTitle style={{ display: "flex", alignItems: "center" }}>
                    <img src="/images/logo.svg" width={25} alt="logo" />&nbsp;CallR Playground
                </DialogTitle>

                <DialogContent>

                    <div className='kt-home-body' style={{ minHeight: "300px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                        <h1>Zahnarzt Dr. Schmidt</h1>
                        <h3>Hat keine Zeit um persönlich ans Telefon zu gehen. Sprechen Sie mit seinem Clone.</h3>
                        <br/>
                        <br/>
                        {callIsActive ?
                        <>
                            <div className="audio-playing-animation">
                                <div className="wave"></div>
                                <div className="wave"></div>
                                <div className="wave"></div>
                            </div>
                            <br/>
                            <style>{`
                                .audio-playing-animation {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                                .wave {
                                    width: 8px;
                                    height: 8px;
                                    margin: 0 2px;
                                    background-color: red;
                                    border-radius: 50%;
                                    animation: pulse 2s infinite ease-in-out;
                                }
                                @keyframes pulse {
                                    0%, 100% {
                                        transform: scale(1);
                                        opacity: 0.3;
                                    }
                                    50% {
                                        transform: scale(1.5);
                                        opacity: 1;
                                    }
                                }
                            `}</style>

                            <Button variant='contained' size="large" onClick={stopCall}><i className='fa fa-phone'></i>&nbsp;AUFLEGEN</Button>
                            </>
                            :
                            <Button variant='contained' size="large" color="secondary" onClick={startCall}><i className='fa fa-phone'></i>&nbsp;ANRUFEN</Button>
                        }

                    </div>

                    <Typography sx={{ mt: 2, textAlign: "center" }}>
                        © 2025 Pickadoc GmbH
                    </Typography>

                </DialogContent>
            </Dialog>

        </div >

    );
}
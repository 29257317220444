import React, { useState, useEffect } from 'react';
import {
    Link
} from "react-router-dom";

import axios from 'axios';
import AudioRecorder, { AudioRecorderStatus } from '../../AudioRecorder';
import VoicePlayback from './voicePlayback';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';

const steps = ['Start', 'Aufnahme', 'Fertig'];

export default function VoicePlaygroundPage() {

    const XI_API_KEY = "cc9c6633f7b52dc8ba0b6facba2cd6ed";
    const voices_url = "https://api.elevenlabs.io/v1/voices";
    const add_voice_url = "https://api.elevenlabs.io/v1/voices/add";


    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [selectedVoiceId, setSelectedVoiceId] = useState("");

    const [audioRecordingURL, setAudioRecordingURL] = useState("");
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

    const [activeStep, setStep] = useState(0);

    const [recordingStatus, setRecordingStatus] = useState(AudioRecorderStatus.inactive);

    useEffect(() => {

        // init is disabled for trade show "medica 2024"
        // init();

    }, []);

    async function init() {
        const _customVoiceId = localStorage.getItem("audioVoiceId");


        const _formFilled = localStorage.getItem("formFilled");
        if (_formFilled) {
            setStep(2);
        }

        //localStorage.setItem("audioVoiceId", "VR6AewLTigWG4xSOukaG");

        if (_customVoiceId) {

            const _voices = await getVoices(_customVoiceId);

            setIsLoading(false);

            if (_voices && _voices.findIndex(v => v.voice_id === _customVoiceId) > -1) {
                setSelectedVoiceId(_customVoiceId);

                setStep(2);
            }

        }

    }

    function handleFinishedRecording(newAudioUrl: string, newAudioBlob: Blob) {
        setAudioRecordingURL(newAudioUrl);
        setAudioBlob(newAudioBlob);
        setRecordingStatus(AudioRecorderStatus.finished);
    }

    async function getVoices(_customVoiceId: string | null): Promise<{ voice_id: string, name: string }[] | null> {

        try {
            // Set options for the API request.
            const options = {
                method: 'GET',
                url: voices_url,
                headers: {
                    'content-type': 'application/json', // Set the content type to application/json.
                    'xi-api-key': `${XI_API_KEY}`, // Set the API key in the headers.
                }
            };

            // Send the API request using Axios and wait for the response.
            const result = await axios.request(options);

            if (result && result.data && result.data.voices && result.data.voices.length > 0) {
                return result.data.voices;
            }

        } catch (err) {
            alert(err);
        }

        return null;
    }

    async function addVoice(voiceName: string): Promise<string> {

        try {
            const data = {
                name: voiceName,
                files: audioBlob
            } as any;

            const options = {
                headers: {
                    'xi-api-key': `${XI_API_KEY}`, // Set the API key in the headers.
                }
            } as any;

            const result: any = await axios.postForm(add_voice_url, data, options);

            return result.data.voice_id;

        } catch (err) {
            alert(err);
        }

        return "";
    }

    //* Convert blob to base64
    const blobToData = (blob: Blob) => {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(blob)
        })
    }


    async function uploadRecording() {

        if (!audioBlob) return;

        // first check if the user has spoken the correct text
        var base64AudioData = await blobToData(audioBlob);
        console.log(base64AudioData);

        var reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = function () {
            // var base64String = reader.result;
            // console.log('Base64 String - ', base64String);
        }

        const _voiceId = await addVoice("Los Grecos");

        if (_voiceId !== "") {
            localStorage.setItem("audioVoiceId", _voiceId);

            setSelectedVoiceId(_voiceId);

            setStep(2);
        }
    }

    function StartSlide() {
        return (
            <div style={
                {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "50px 0"
                }
            }>

                <img src="../images/ai-avatar.JPG" alt="AI avatar"></img>
                <br />
                <h1 style={{ textAlign: "center" }}>AI Rapid Voice Cloning</h1>
                <p>Klone deine Stimme in nur wenigen Sekunden.</p>
                <div className="kt-form-ctrl">
                    <Button variant='contained' size="large" color='secondary' onClick={() => setStep(1)}>KLONE MEINE STIMME</Button>
                </div>

            </div>
        )
    }

    function RecordingSlide() {
        return (
            <div style={
                {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "50px 0"

                }
            }>

                <span className='kt-secondary-color'>Sprechen Sie bitte nun folgenden Text:</span>
                <p>
                    Ich bin damit einverstanden, dass meine Stimme von Pickadoc geklont wird.
                    Ich habe die volle Kontrolle über die Inhalte, die mit meiner Stimme generiert werden.
                    Das Ziel besteht darin, mit ein paar Sekunden Audio synthetische Stimmen zu erzeugen, die genau wie ich klingen.
                    Generierte Inhalte können für Videos, Videoavatare, Instagram, YouTube und vieles mehr verwendet werden.
                </p>

                <AudioRecorder onFinishedRecording={(newAudioUrl, newBlob) => handleFinishedRecording(newAudioUrl, newBlob)} />

                {audioRecordingURL && <audio src={audioRecordingURL} controls />}

                <br />
                {recordingStatus === AudioRecorderStatus.finished &&
                    <div className="kt-form-ctrl">
                        <Button variant='contained' color='secondary' onClick={() => uploadRecording()}>WEITER</Button>
                    </div>
                }

            </div>
        )
    }



    return (
        <div className="kt-playgroundPage kt-calendr-playgroundPage">
            {/* <div className="kt-homePageNew kt-voicePlaygroundPage"> */}

            <Dialog
                open={true}
                maxWidth="md"
                fullWidth
                className='kt-playgroundPage-dialog'
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                        ".MuiPaper-root": { borderRadius: "20px !important" }
                    }
                }}
            >
                <DialogTitle style={{ display: "flex", alignItems: "center" }}>
                    <img src="/images/logo.svg" width={25} alt="logo" />&nbsp;Voice Playground
                </DialogTitle>

                <DialogContent>

                    <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={activeStep > index}>
                                <StepButton color="inherit">
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>

                    <div className='kt-home-body'>
                        {/* <img src="../images/playground.svg" style={{ width: "200px", position: "absolute", top: "92px" }} alt="AI avatar"></img> */}

                        {activeStep === 0 && <StartSlide />}
                        {activeStep === 1 && <RecordingSlide />}
                        {activeStep === 2 && <VoicePlayback voiceId={selectedVoiceId} />}

                    </div>


                    <Typography sx={{ mt: 2, textAlign: "center" }}>
                        © 2025 Pickadoc GmbH
                    </Typography>

                </DialogContent>
            </Dialog>

        </div >

    );
}